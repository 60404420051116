import React, { useEffect, useState } from "react";
import HomeBanner from "./Home-Banne/HomeBanner";
import HomeNavbar from "./Home-Navbar/HomeNavbar";
import { getCurrentTournamentsReq } from "./__request/HomePagerequests";
import {
  getResultByDate,
  getTotalDaysResultReq,
  GetTournamentOwnersReq,
} from "../adminPanal/create-tournaments/__request/CraeteTournamentRequest";
import { Button } from "react-bootstrap";

const Home = () => {
  const [currentTournament, setCurrentTournament] = useState();

  const getCurrentTournament = async () => {
    try {
      const response = await getCurrentTournamentsReq();
      setCurrentTournament(response?.data);
    } catch (err) {
      console.error("Error in fetching current Tournament");
    }
  };

  useEffect(() => {
    getCurrentTournament();
  }, []);

  const [resultDate, setResultDate] = useState();

  useEffect(() => {
    if (currentTournament?.dates?.length) {
      const selectedDate = new Date(currentTournament?.dates[0]);

      // Extract the day, month, and year components
      const day = String(selectedDate.getDate()).padStart(2, "0"); // Ensures two digits for day
      const month = String(selectedDate.getMonth() + 1).padStart(2, "0"); // Adjusts for 0-based month index
      const year = selectedDate.getFullYear();

      // Format the date as DD-MM-YYYY
      const formattedDate = `${day}-${month}-${year}`;

      setResultDate((prev) => ({
        ...prev,
        date: formattedDate,
        startTime: currentTournament?.startTime,
      }));
    }
  }, [currentTournament]);

  const [selectedDateIndex, setSelectedDateIndex] = useState(0);

  const handleDateSelect = (date, index) => {
    const selectedDate = new Date(date);

    const day = String(selectedDate.getDate()).padStart(2, "0");
    const month = String(selectedDate.getMonth() + 1).padStart(2, "0");
    const year = selectedDate.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;

    setResultDate((prev) => ({
      ...prev,
      date: formattedDate,
    }));
    setSelectedDateIndex(index); // Update the selected index
  };

  useEffect(() => {
    if (currentTournament?.dates?.length) {
      const firstDate = currentTournament?.dates[0];
      handleDateSelect(firstDate, 0); // Select the first date by default
    }
  }, [currentTournament]);
  // xxxxxxxxxxxxxxxxxxxxxxxxxx>> Get tournament owners <<xxxxxxxxxxxxxxxxxxxxxxxxxx

  const [owners, setOwners] = useState();

  const GetTournamentOwners = async () => {
    try {
      const response = await GetTournamentOwnersReq(currentTournament?._id);
      console.log("GetTournamentOwnersReq", response);
      setOwners(response);
    } catch (err) {
      console.error("Error in getting Tourament Owners", err);
    }
  };

  useEffect(() => {
    if (currentTournament?._id) {
      GetTournamentOwners();
    }
  }, [currentTournament]);

  // xxxxxxxxxxxxxxxxxxxxxxxxxx>> Get tournament Result <<xxxxxxxxxxxxxxxxxxxxxxxxxx

  const [gerResult, setGetResult] = useState([]);

  const resultByDate = async () => {
    try {
      const response = await getResultByDate(
        currentTournament?._id,
        resultDate?.date
      );
      setGetResult(response);
    } catch (err) {
      console.error("Error in gettin result by Date", err);
    }
  };

  useEffect(() => {
    resultByDate();
  }, [resultDate]);

  const [totalDaysResult, setTotalDaysResult] = useState([]);

  const getTotalDaysResult = async () => {
    try {
      const response = await getTotalDaysResultReq(currentTournament?._id);
      setTotalDaysResult(response);
    } catch (err) {
      console.error("Error in getting Total Days Result", err);
    }
  };

  useEffect(() => {
    getTotalDaysResult();
  }, [currentTournament]);

  // Add a new state for tracking if total view is selected
  const [showTotal, setShowTotal] = useState(false);

  // Find global highest time across all owners and pigeons
  const findGlobalHighestTime = () => {
    if (showTotal) {
      if (!totalDaysResult?.ownerResults) return null;
      let highest = 0;
      let highestOwnerId = null;
      let highestPigeonIndex = null;

      totalDaysResult.ownerResults.forEach((owner) => {
        owner.pigeons?.forEach((pigeon, index) => {
          if (pigeon.totalTime && pigeon.totalTime > highest) {
            highest = pigeon.totalTime;
            highestOwnerId = owner.ownerId;
            highestPigeonIndex = index;
          }
        });
      });
      return {
        time: highest,
        ownerId: highestOwnerId,
        pigeonIndex: highestPigeonIndex,
      };
    } else {
      if (!Array.isArray(gerResult)) return null;
      let highest = "00:00";
      let highestOwnerId = null;
      let highestPigeonIndex = null;

      gerResult.forEach((owner) => {
        owner.timeList?.forEach((time, index) => {
          if (time && time > highest) {
            highest = time;
            highestOwnerId = owner.pigeonOwnerId;
            highestPigeonIndex = index;
          }
        });
      });
      return {
        time: highest,
        ownerId: highestOwnerId,
        pigeonIndex: highestPigeonIndex,
      };
    }
  };

  const findFirstPigeonHighestTime = () => {
    if (showTotal) {
      if (!totalDaysResult?.ownerResults) return null;
      let highest = 0;
      let highestOwnerId = null;

      totalDaysResult.ownerResults.forEach((owner) => {
        // Find the first non-excluded pigeon
        const firstValidPigeon = owner.pigeons?.find(
          (pigeon, index) =>
            !owner.excludedIndices?.includes(index) && pigeon.totalTime
        );

        if (
          firstValidPigeon?.totalTime &&
          firstValidPigeon.totalTime > highest
        ) {
          highest = firstValidPigeon.totalTime;
          highestOwnerId = owner.ownerId;
        }
      });
      return { time: highest, ownerId: highestOwnerId };
    } else {
      if (!Array.isArray(gerResult)) return null;
      let highest = "00:00";
      let highestOwnerId = null;

      gerResult.forEach((owner) => {
        // Find index of first non-excluded time
        const firstValidIndex = owner.timeList?.findIndex(
          (time, index) => !owner.excludedIndices?.includes(index) && time
        );

        if (firstValidIndex !== -1) {
          const firstValidTime = owner.timeList[firstValidIndex];
          if (firstValidTime && firstValidTime > highest) {
            highest = firstValidTime;
            highestOwnerId = owner.pigeonOwnerId;
          }
        }
      });
      return { time: highest, ownerId: highestOwnerId };
    }
  };

  return (
    <div className="">
      <HomeBanner />
      <HomeNavbar />
      <div
        className="w-100 d-flex align-items-center justify-content-start p-1"
        style={{ backgroundColor: "#608BC1" }}
      >
        <span className="fw-bold text-white fs-5">
          Currect Tournament : {currentTournament?.tournamentName}
        </span>
      </div>

      <div
        className="w-100 d-flex align-items-center justify-content-center gap-3 flex-wrap"
        style={{ padding: "10px" }}
      >
        {currentTournament?.dates?.map((date, index) => {
          const isSelected = !showTotal && selectedDateIndex === index;
          return (
            <Button
              key={index}
              variant={isSelected ? "primary" : "outline-primary"}
              onClick={() => {
                handleDateSelect(date, index);
                setShowTotal(false);
              }}
              className={`px-4 py-2 rounded-pill shadow-sm ${
                isSelected ? "fw-bold text-white" : "text-primary"
              }`}
              style={{
                minWidth: "160px",
                border: isSelected ? "none" : "2px solid #0d6efd",
                transform: isSelected ? "scale(1.05)" : "scale(1)",
                transition: "all 0.3s ease-in-out",
                backgroundColor: isSelected ? "#0d6efd" : "white",
                boxShadow: isSelected
                  ? "0 4px 12px rgba(13, 110, 253, 0.3)"
                  : "0 2px 6px rgba(0, 0, 0, 0.1)",
                letterSpacing: "0.5px",
              }}
            >
              {new Date(date).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
            </Button>
          );
        })}
        <Button
          variant={showTotal ? "primary" : "outline-primary"}
          onClick={() => setShowTotal(true)}
          className={`px-4 py-2 rounded-pill shadow-sm ${
            showTotal ? "fw-bold text-white" : "text-primary"
          }`}
          style={{
            minWidth: "160px",
            border: showTotal ? "none" : "2px solid #0d6efd",
            transform: showTotal ? "scale(1.05)" : "scale(1)",
            transition: "all 0.3s ease-in-out",
            backgroundColor: showTotal ? "#0d6efd" : "white",
            boxShadow: showTotal
              ? "0 4px 12px rgba(13, 110, 253, 0.3)"
              : "0 2px 6px rgba(0, 0, 0, 0.1)",
            letterSpacing: "0.5px",
          }}
        >
          Total
        </Button>
      </div>

      <div
        className=""
        style={{
          padding: "10px",
          border: " solid #dee2e6",
          margin: "10px",
          borderRadius: "10px",
          boxShadow: "2px 0 8px rgba(40, 167, 69, 0.2)",
          backgroundColor: "#FAFFC5",
        }}
      >
        <span className="fw-bold" style={{ color: "#2C3E50" }}>
          Today's first pigeon winner:
        </span>{" "}
        <span>
          {(() => {
            const firstPigeonHighest = findFirstPigeonHighestTime();
            if (!firstPigeonHighest || !firstPigeonHighest.time)
              return "No results yet";

            const winnerOwner =
              owners?.find((o) => o._id === firstPigeonHighest.ownerId)?.name ||
              "";

            if (showTotal) {
              const hours = Math.floor(firstPigeonHighest.time / 3600);
              const minutes = Math.floor((firstPigeonHighest.time % 3600) / 60);
              return `${hours}h ${minutes}m, ${winnerOwner}`;
            } else {
              const [hours, minutes] = firstPigeonHighest.time.split(":");
              return `${hours}:${minutes}, ${winnerOwner}`;
            }
          })()}
        </span>
      </div>

      <div
        className=""
        style={{
          padding: "10px",
          border: "1px solid #dee2e6",
          margin: "10px",
          borderRadius: "10px",
          borderLeft: "10px solid #133E87",
          boxShadow: "2px 0 8px rgba(40, 167, 69, 0.2)",
          backgroundColor: "#D0DDD0",
        }}
      >
        <div className="d-flex align-items-center gap-3">
          <div>
            <span className="fw-bold" style={{ color: "#2C3E50" }}>
              Lofts:
            </span>{" "}
            <span>{currentTournament?.participatingLofts?.length}</span>
          </div>
          <div>
            <span className="fw-bold" style={{ color: "#2C3E50" }}>
              Pigeons:
            </span>{" "}
            <span>
              {(currentTournament?.numberOfPigeons +
                (currentTournament?.helperPigeons || 0)) *
                currentTournament?.participatingLofts?.length}
            </span>{" "}
          </div>

          <div>
            <span className="fw-bold" style={{ color: "#2C3E50" }}>
              Landed:
            </span>{" "}
            <span>
              {showTotal
                ? totalDaysResult?.ownerResults?.reduce((total, owner) => {
                    return (
                      total +
                      owner.pigeons?.filter(
                        (pigeon) => pigeon.totalTime !== null
                      ).length
                    );
                  }, 0) || 0
                : Array.isArray(gerResult)
                ? gerResult.reduce((total, owner) => {
                    return (
                      total +
                      (owner.timeList?.filter((time) => time !== null)
                        ?.length || 0)
                    );
                  }, 0)
                : 0}
            </span>{" "}
          </div>

          <div>
            <span className="fw-bold" style={{ color: "#2C3E50" }}>
              Pigeons remaining:
            </span>{" "}
            <span>
              {(() => {
                const totalPigeons =
                  (currentTournament?.numberOfPigeons +
                    (currentTournament?.helperPigeons || 0)) *
                  currentTournament?.participatingLofts?.length;

                const landedPigeons = showTotal
                  ? totalDaysResult?.ownerResults?.reduce((total, owner) => {
                      return (
                        total +
                        (owner.pigeons?.filter(
                          (pigeon) => pigeon.totalTime !== null
                        ).length || 0)
                      );
                    }, 0) || 0
                  : Array.isArray(gerResult)
                  ? gerResult.reduce((total, owner) => {
                      return (
                        total +
                        (owner.timeList?.filter((time) => time !== null)
                          ?.length || 0)
                      );
                    }, 0)
                  : 0;

                return Math.max(0, totalPigeons - landedPigeons);
              })()}
            </span>
          </div>
        </div>

        {showTotal
          ? totalDaysResult?.ownerResults?.length > 0 && (
              <>
                <span className="fw-bold" style={{ color: "#2C3E50" }}>
                  Tournament winner pigeon time:
                </span>{" "}
                <span>
                  {(() => {
                    let maxTime = 0;
                    let winnerOwner = "";
                    totalDaysResult.ownerResults.forEach((owner) => {
                      owner.pigeons?.forEach((pigeon) => {
                        if (pigeon.totalTime && pigeon.totalTime > maxTime) {
                          maxTime = pigeon.totalTime;
                          winnerOwner =
                            owners?.find((o) => o._id === owner.ownerId)
                              ?.name || "";
                        }
                      });
                    });
                    const hours = Math.floor(maxTime / 3600);
                    const minutes = Math.floor((maxTime % 3600) / 60);
                    return maxTime
                      ? `${hours}h ${minutes}m, ${winnerOwner}`
                      : "No results yet";
                  })()}
                </span>
              </>
            )
          : gerResult?.length > 0 && (
              <>
                <span className="fw-bold" style={{ color: "#2C3E50" }}>
                  Last pigeon winner:
                </span>{" "}
                <span>
                  {(() => {
                    const globalHighest = findGlobalHighestTime();
                    if (!globalHighest || !globalHighest.time)
                      return "No results yet";

                    const winnerOwner =
                      owners?.find((o) => o._id === globalHighest.ownerId)
                        ?.name || "";

                    if (showTotal) {
                      const hours = Math.floor(globalHighest.time / 3600);
                      const minutes = Math.floor(
                        (globalHighest.time % 3600) / 60
                      );
                      return `${hours}h ${minutes}m, ${winnerOwner}`;
                    } else {
                      const [hours, minutes] = globalHighest.time.split(":");
                      return `${hours}:${minutes}, ${winnerOwner}`;
                    }
                  })()}
                </span>
                <br />
              </>
            )}
      </div>

      <div className="card-body p-0">
        <table
          className="table table-sm table-hover table-striped bordered-table mb-0"
          style={{
            fontSize: "0.9rem", // Even smaller font
            borderCollapse: "collapse",
            border: "1px solid #dee2e6", // Add border to whole table
          }}
        >
          <thead>
            <tr
              style={{
                backgroundColor: "#f8f9fa",
                borderBottom: "2px solid #dee2e6",
              }}
            >
              <th scope="col" className="text-center p-0 border">
                #
              </th>
              <th scope="col" className="text-start p-0 border">
                Owner
              </th>
              <th scope="col" className="text-start p-0 border">
                Pigeons
              </th>
              <th scope="col" className="text-start p-0 border">
                Time
              </th>
              {/* Regular pigeons */}
              {Array.from(
                { length: currentTournament?.numberOfPigeons },
                (_, index) => (
                  <th key={index} scope="col" className="text-start p-1 border">
                    #{index + 1}
                  </th>
                )
              )}
              {/* Helper pigeons - continuing the numbering */}
              {Array.from(
                { length: currentTournament?.helperPigeons || 0 },
                (_, index) => (
                  <th
                    key={`helper-${index}`}
                    scope="col"
                    className="text-start p-1 border"
                  >
                    #{currentTournament?.numberOfPigeons + index + 1}
                  </th>
                )
              )}
              <th scope="col" className="text-center p-0 border">
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {owners?.map((owner, idx) => {
              const ownerResult = showTotal
                ? totalDaysResult?.ownerResults?.find(
                    (result) => result.ownerId === owner?._id
                  )
                : Array.isArray(gerResult)
                ? gerResult?.find(
                    (result) => result?.pigeonOwnerId === owner?._id
                  )
                : null;
              console.log("ownerResult", ownerResult);

              return (
                <tr
                  key={owner?._id}
                  style={{
                    backgroundColor: idx % 2 === 0 ? "#ffffff" : "#f8f9fa",
                  }}
                  className="hover-row"
                >
                  <td className="text-center p-1 border">{idx + 1}</td>
                  <td className="text-start p-1 border">
                    <div className="d-flex align-items-center justify-content-start gap-2">
                      <div
                        className="overflow-hidden"
                        style={{
                          height: "50px",
                          width: "50px",
                          borderRadius: "100%",
                        }}
                      >
                        <img
                          src={owner?.ownerPicture}
                          className="rounded-3 h-100 w-100 object-fit-cover"
                          alt=""
                        />
                      </div>
                      <div className="d-flex flex-column align-items-start justify-content-start fw-bold">
                        {owner?.name}
                        <span className="fw-normal">{owner?.address}</span>
                      </div>
                    </div>
                  </td>
                  <td className="text-start p-1 border">
                    {currentTournament?.numberOfPigeons}
                  </td>
                  <td className="text-start p-1 border">
                    {(
                      (gerResult?.length > 0 &&
                        gerResult?.find((o) => o.pigeonOwnerId === owner?._id)
                          ?.startTime) ||
                      currentTournament?.startTime
                    )?.slice(0, 5)}
                  </td>
                  {Array.from(
                    {
                      length:
                        currentTournament?.numberOfPigeons +
                        (currentTournament?.helperPigeons || 0),
                    },
                    (_, index) => {
                      const pigeonTime = showTotal
                        ? ownerResult?.pigeons?.[index]?.totalTime
                        : ownerResult?.timeList?.[index];
                      const formattedPigeonTime =
                        pigeonTime?.length > 0 &&
                        pigeonTime?.split(":").slice(0, 2).join(":");

                      const globalHighest = findGlobalHighestTime();
                      const isHighestTime =
                        globalHighest &&
                        owner._id === globalHighest.ownerId &&
                        index === globalHighest.pigeonIndex;

                      const isExcluded =
                        ownerResult?.excludedIndices?.includes(index);
                      const isHelper =
                        index >= currentTournament?.numberOfPigeons;

                      return (
                        <td
                          key={index}
                          className={`text-start p-1 border ${
                            isExcluded ? "text-muted" : ""
                          }`}
                          style={{
                            backgroundColor: isHighestTime ? "#78B3CE" : "",
                            color: isHighestTime ? "white" : "inherit",
                          }}
                        >
                          {showTotal
                            ? pigeonTime
                              ? (() => {
                                  const hours = Math.floor(pigeonTime / 3600);
                                  const minutes = Math.floor(
                                    (pigeonTime % 3600) / 60
                                  );
                                  return `${hours}:${minutes}${
                                    isExcluded ? "" : ""
                                  }`;
                                })()
                              : isExcluded
                              ? "(excluded)"
                              : ""
                            : pigeonTime
                            ? `${formattedPigeonTime}${isExcluded ? "" : ""}`
                            : isExcluded
                            ? "(excluded)"
                            : ""}
                        </td>
                      );
                    }
                  )}
                  <td className="text-center p-1 border">
                    {showTotal
                      ? ownerResult?.formattedGrandTotal
                        ? ownerResult?.formattedGrandTotal?.slice(0, 5)
                        : "No Result"
                      : gerResult?.length > 0
                      ? gerResult?.map((result, index) =>
                          result.pigeonOwnerId === owner?._id ? (
                            <div key={index}>
                              {(result.formattedTotalTime || "00:00:00").slice(
                                0,
                                5
                              )}
                            </div>
                          ) : null
                        )
                      : "No Result"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Home;
