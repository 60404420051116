import React, { useEffect, useState } from "react";
import HomeBanner from "../../Home-Banne/HomeBanner";
import HomeNavbar from "../HomeNavbar";
import { getClubTournaments } from "./__requests/ClubTournamentsRequests";
import { useSelector } from "react-redux";
import {
  GetTournamentOwnersReq,
  getTotalDaysResultReq,
} from "../../../adminPanal/create-tournaments/__request/CraeteTournamentRequest";
import {
  clunRefreshDispatcher,
  tournamentDataDispatcher,
} from "../../../../redux/action";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ScaleLoader } from "react-spinners";

const ClubAllTournaments = () => {
  const [clubAllTournaments, setClubAllTournaments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tournamentOwners, setTournamentOwners] = useState({});
  const [tournamentResults, setTournamentResults] = useState({});
  const [activeYear, setActiveYear] = useState(null);
  const clubName = useSelector((state) => state.clubNameReducer);
  const refreshClub = useSelector((state) => state.clubRefreshReducer);

  const dispatch = useDispatch();

  const handleClubTournaments = async () => {
    try {
      setLoading(true);
      const response = await getClubTournaments(clubName);
      if (response?.success === true) {
        setClubAllTournaments(response?.tournaments || []);
        response?.tournaments.forEach((tournament) => {
          fetchTournamentOwners(tournament._id);
          fetchTournamentResults(tournament._id);
        });
      } else {
        setClubAllTournaments([]);
      }
      dispatch(clunRefreshDispatcher(false));
    } catch (err) {
      setClubAllTournaments([]);
      console.error("Error in fetching club tournaments");
    } finally {
      setLoading(false);
    }
  };

  const fetchTournamentOwners = async (tournamentId) => {
    try {
      const response = await GetTournamentOwnersReq(tournamentId);
      setTournamentOwners((prev) => ({
        ...prev,
        [tournamentId]: response,
      }));
    } catch (err) {
      console.error("Error fetching tournament owners", err);
    }
  };

  const fetchTournamentResults = async (tournamentId) => {
    try {
      const response = await getTotalDaysResultReq(tournamentId);
      setTournamentResults((prev) => ({
        ...prev,
        [tournamentId]: response,
      }));
    } catch (err) {
      console.error("Error fetching tournament results", err);
    }
  };

  const getSortedOwners = (tournamentId, tournament) => {
    const owners = tournamentOwners[tournamentId] || [];
    const results = tournamentResults[tournamentId]?.ownerResults || [];
    const prizes = [...(tournament.prizes || [])].sort(
      (a, b) => Number(b) - Number(a)
    ); // Sort prizes highest to lowest

    // Combine owners with their results
    const ownersWithResults = owners.map((owner) => {
      const ownerResult = results.find(
        (result) => result.ownerId === owner._id
      );
      return {
        ...owner,
        grandTotal: ownerResult?.grandTotal || 0,
      };
    });

    // Sort by grandTotal (highest to lowest)
    const sortedOwners = ownersWithResults.sort(
      (a, b) => b.grandTotal - a.grandTotal
    );

    // Assign prizes based on ranking
    return sortedOwners.map((owner, index) => ({
      ...owner,
      prize: index < prizes.length ? prizes[index] : null,
    }));
  };

  const formatTime = (seconds) => {
    if (!seconds) return "No Result";
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  };

  // Add this new function to get unique years and sort them
  const getUniqueYears = () => {
    const years = clubAllTournaments.map(tournament => 
      new Date(tournament.dates[0]).getFullYear()
    );
    return [...new Set(years)].sort((a, b) => b - a); // Sort descending
  };

  // Add this function to filter and sort tournaments
  const getFilteredTournaments = () => {
    return clubAllTournaments
      .filter(tournament => {
        const tournamentYear = new Date(tournament.dates[0]).getFullYear();
        return tournamentYear === activeYear;
      })
      .sort((a, b) => new Date(a.dates[0]) - new Date(b.dates[0])); // Sort by month
  };

  // Modify useEffect to set initial active year
  useEffect(() => {
    handleClubTournaments();
  }, [refreshClub, clubName]);

  useEffect(() => {
    const years = getUniqueYears();
    if (years.length > 0 && !activeYear) {
      setActiveYear(years[0]); // Set to most recent year by default
    }
  }, [clubAllTournaments]);

  return (
    <div className="">
      <HomeBanner />
      <HomeNavbar />
      <div
        className="w-100 d-flex align-items-center justify-content-start p-1"
        style={{ backgroundColor: "#608BC1" }}
      >
        <span className="fw-bold text-white fs-5">Club Name : {clubName}</span>
      </div>

      <div className="container mt-3">
        <div className="d-flex gap-2 mb-3">
          {getUniqueYears().map(year => (
            <button
              key={year}
              className={`btn ${activeYear === year ? 'btn-primary' : 'btn-outline-primary'}`}
              onClick={() => setActiveYear(year)}
            >
              {year}
            </button>
          ))}
        </div>
      </div>

      <div className="container mt-4">
        {loading ? (
          <div
            className="card p-5 d-flex justify-content-center align-items-center"
            style={{ minHeight: "200px" }}
          >
            <ScaleLoader color="#0d6efd" />
          </div>
        ) : clubAllTournaments.length === 0 ? (
          <div className="card p-5 text-center">
            <h4 className="text-muted">No tournaments available</h4>
            <p className="text-secondary">
              There are currently no tournaments in this club.
            </p>
          </div>
        ) : (
          getFilteredTournaments().map((tournament) => {
            const sortedOwners = getSortedOwners(tournament._id, tournament);

            return (
              <div key={tournament._id} className="card mb-4">
                <div className="d-flex align-items-start">
                  {" "}
                  <img
                    src={tournament?.tournamentPicture}
                    height={150}
                    width={150}
                    alt=""
                    className="rounded"
                  />{" "}
                  <div className="d-flex flex-column w-100">
                    <div className="card-header">
                      <div>
                        <Link
                          to="/tournament-view"
                          onClick={() => {
                            dispatch(tournamentDataDispatcher(tournament));
                          }}
                        >
                          <h3 className="mb-2">{tournament.tournamentName}</h3>
                        </Link>
                        <p className="text-muted">
                          {new Date(tournament.dates[0]).toLocaleDateString()} -{" "}
                          {tournament.numberOfDays} days
                        </p>
                      </div>
                    </div>
                    <div className="card-body">
                      <table className="table table-striped table-hover">
                        <thead>
                          <tr className="bg-light">
                            <th>#</th>
                            <th>Owner Name</th>
                            <th>Location</th>
                            <th>Time</th>
                            <th>Prize</th>
                          </tr>
                        </thead>
                        <tbody>
                          {sortedOwners.map((owner, index) => (
                            <tr
                              key={owner._id}
                              className={index === 0 ? "table-primary" : ""}
                            >
                              <td>{index + 1}</td>
                              <td>
                                <div className="d-flex align-items-center gap-2">
                                  <img
                                    src={owner.ownerPicture}
                                    alt=""
                                    className="rounded-circle"
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      objectFit: "cover",
                                      border:
                                        index < 3
                                          ? "2px solid #28a745"
                                          : "none",
                                    }}
                                  />
                                  <span className="fw-bold">{owner.name}</span>
                                </div>
                              </td>
                              <td>{owner.address}</td>
                              <td
                                className={
                                  index === 0 ? "fw-bold text-success" : ""
                                }
                              >
                                {formatTime(owner.grandTotal)}
                              </td>
                              <td>
                                {owner.prize ? (
                                  <span
                                    className={`fw-bold ${
                                      index === 0 ? "text-success" : ""
                                    }`}
                                  >
                                    {owner.prize}
                                  </span>
                                ) : (
                                  "-"
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      <div className="mt-3 text-muted">
                        <small>
                          <strong>Total Participants:</strong>{" "}
                          {sortedOwners.length} |{" "}
                          <strong>Total Prize Pool:</strong>{" "}
                          {tournament.prizes.reduce(
                            (a, b) => Number(a) + Number(b),
                            0
                          )}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default ClubAllTournaments;
