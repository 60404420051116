import axiosInstance from "../../../helper/AxiosConfig";

export const getCurrentTournamentsReq = async () => {
  try {
    const response = await axiosInstance.get(
      `/sona-punjab/get-active-tournament`
    );
    return response.data;
  } catch (error) {
    console.error("Error in fetching current Tournaments", error.message);
    return { data: null, error: "Error in fetching current Tournaments" };
  }
};
