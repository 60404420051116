import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";

const HomePageOne = () => {
  return (
    <>
      <MasterLayout>
        <div className="w-100 d-flex align-items-center justify-content-center fs-2">
          Welcome to Dashboard
        </div>
      </MasterLayout>
    </>
  );
};

export default HomePageOne;
