import React, { useEffect, useRef, useState } from "react";
import MasterLayout from "../../../masterLayout/MasterLayout";
import { FaPlus } from "react-icons/fa6";
import { IoTrashOutline } from "react-icons/io5";
import "./CreateTournaments.css";
import { CraeteTournamentRequest } from "./__request/CraeteTournamentRequest";
import { Button } from "react-bootstrap";
import { getPigeonOwnersReq } from "../pigeon-owners/__request/PigeonOwnersRequest";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { getAllClubs } from "../club/__requests/ClubRequests";

const CreateTournaments = () => {
  const fileInputRef = useRef();
  const [pigeonOwners, setPigeonOwners] = useState();
  const [imageSrc, setImageSrc] = useState(null);
  const [numDays, setNumDays] = useState(0);
  const [dateFields, setDateFields] = useState([]);
  const [numberOfPrizes, setNumberOfPrizes] = useState(0);
  const [selectedOwners, setSelectedOwners] = useState([]);
  const loginUser = useSelector((state) => state.userDataReducer);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    tournamentPicture: "",
    club: "",
    tournamentName: "",
    startDate: "",
    startTime: "",
    numberOfDays: "",
    numberOfPigeons: "",
    helperPigeons: "",
    continueDays: "",
    status: "Non-Active",
    participatingLofts: [],
    numberOfPrizes: "",
    dates: [],
    prizes: [],
    noteTimeForPigeons: "",
  });
  const handleRowClick = (ownerId) => {
    setSelectedOwners(
      (prev) =>
        prev?.includes(ownerId)
          ? prev.filter((id) => id !== ownerId) // Remove if already selected
          : [...prev, ownerId] // Add if not selected
    );

    // Update participatingLofts in formData
    setFormData((prev) => ({
      ...prev,
      participatingLofts: prev?.participatingLofts?.includes(ownerId)
        ? prev.participatingLofts.filter((id) => id !== ownerId)
        : [...prev.participatingLofts, ownerId],
    }));
  };

  const handleDivClick = () => {
    if (imageSrc) {
      setImageSrc(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } else if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDaysChange = (event) => {
    const value = event.target.value;
    const numberOfDays = Math.min(value ? parseInt(value, 10) : 0, 50);
    setNumDays(numberOfDays);

    setFormData((prevData) => ({
      ...prevData,
      numberOfDays: numberOfDays,
      dates: Array(numberOfDays).fill(""),
    }));

    const newDateFields = Array.from(
      { length: numberOfDays },
      (_, index) => index + 1
    );
    setDateFields(newDateFields);
  };

  const handleNumberChange = (e) => {
    const value = parseInt(e.target.value, 10);
    const limitedValue = Math.min(isNaN(value) ? 0 : value, 50);
    setNumberOfPrizes(limitedValue);

    // Update formData directly
    setFormData((prevData) => ({
      ...prevData,
      numberOfPrizes: limitedValue,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Special handling for numberOfPigeons and helperPigeons
    if (name === "numberOfPigeons" || name === "helperPigeons") {
      const numValue = Math.min(parseInt(value, 10) || 0, 50);
      setFormData((prevData) => ({
        ...prevData,
        [name]: numValue,
      }));
      return;
    }

    // Handle other inputs normally
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files?.[0];
    if (file && file.type.startsWith("image/")) {
      setFormData({
        ...formData,
        tournamentPicture: file,
      });
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await CraeteTournamentRequest(formData);
      if (response) {
        toast.success("Tournament created successfully!");
        navigate("/all-tournaments", { state: { refresh: true } });
      }
    } catch (err) {
      console.error("Error in creating tournament", err);
      toast.error("Failed to create tournament");
    } finally {
      setLoading(false);
    }
  };

  // xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx>> Get Pigeon Owner <<xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  const getPigeonOwners = async () => {
    try {
      const response = await getPigeonOwnersReq(loginUser?._id);
      setPigeonOwners(response?.owners);
    } catch (err) {
      console.error("Error in gettin Pigeon Owners", err);
    }
  };

  useEffect(() => {
    getPigeonOwners();
  }, []);
  const [searchQuery, setSearchQuery] = useState("");
  const filteredOwners = pigeonOwners?.filter(
    (owner) =>
      owner.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      owner.address.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    // Function to prevent scroll on number inputs
    const preventScroll = (e) => {
      e.target.blur();
    };

    // Get all number inputs
    const numberInputs = document.querySelectorAll('input[type="number"]');

    // Add event listeners
    numberInputs.forEach((input) => {
      input.addEventListener("focus", (e) => {
        e.target.addEventListener("wheel", preventScroll, { passive: false });
        e.target.addEventListener("touchstart", preventScroll, {
          passive: false,
        });
      });

      input.addEventListener("blur", (e) => {
        e.target.removeEventListener("wheel", preventScroll);
        e.target.removeEventListener("touchstart", preventScroll);
      });
    });

    // Cleanup
    return () => {
      numberInputs.forEach((input) => {
        input.removeEventListener("focus", preventScroll);
        input.removeEventListener("blur", preventScroll);
      });
    };
  }, []);

  const [allClubs, setAllClubs] = useState([]);

  const handleAllClubs = async () => {
    try {
      const response = await getAllClubs();
      if (response?.success === true) {
        setAllClubs(response?.clubs);
      }
    } catch (err) {
      console.log("Error in fetching all clubs", err);
      setAllClubs([]);
    }
  };

  useEffect(() => {
    handleAllClubs();
  }, []);

  return (
    <MasterLayout>
      <div className="w-100 d-flex align-items-center justify-content-center">
        <div className="w-75 color-mode p-5 rounded-2">
          <form className="d-flex flex-column" onSubmit={handleSubmit}>
            <div className="mb-3 d-flex align-items-center flex-column">
              <label htmlFor="tournamentPoster" className="form-label">
                Tournament Poster
              </label>
              <input
                type="file"
                className="d-none"
                id="tournamentPoster"
                accept="image/*"
                ref={fileInputRef}
                onChange={handleFileChange}
              />
              <div
                className="border-secondary border-1 rounded-2 d-flex align-items-center justify-content-center overflow-hidden"
                style={{ height: "180px", width: "180px", cursor: "pointer" }}
                onClick={handleDivClick}
              >
                {imageSrc ? (
                  <>
                    <div className="Conatiner-Tournament-poster">
                      <img
                        src={imageSrc}
                        alt="Preview"
                        className="Tournament-poster"
                      />
                      <span
                        className="Remove-Tournament-poster"
                        onClick={() => handleDivClick()}
                      >
                        <IoTrashOutline />
                      </span>
                    </div>
                  </>
                ) : (
                  <FaPlus className="fs-3" />
                )}
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="tournamentName" className="form-label">
                Tournament Name
              </label>
              <input
                type="text"
                className="form-control"
                id="tournamentName"
                name="tournamentName"
                value={formData.tournamentName}
                onChange={handleInputChange}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="club" className="form-label">
                Category
              </label>
              <select
                className="form-control"
                id="club"
                name="club"
                value={formData.club}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Select an option
                </option>
                {allClubs?.map((club) => {
                  return <option>{club?.name}</option>;
                })}
              </select>
            </div>

            <div className="mb-3">
              <label htmlFor="startDate" className="form-label">
                Start Date
              </label>
              <input
                type="date"
                className="form-control"
                id="startDate"
                name="startDate"
                value={formData.startDate}
                onChange={handleInputChange}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="startTime" className="form-label">
                Start Time
              </label>
              <input
                type="time"
                className="form-control"
                id="startTime"
                name="startTime"
                value={formData.startTime}
                onChange={handleInputChange}
              />
            </div>

            <div className="mb-3">
              <label
                htmlFor="numDays"
                className="form-label d-flex justify-content-between"
              >
                Number of Days <span>Max 50</span>
              </label>
              <input
                type="number"
                className="form-control"
                id="numDays"
                value={formData.numberOfDays || ""}
                onChange={handleDaysChange}
                min="1"
                max="50"
                onwheel={(e) => e.preventDefault()}
              />
            </div>

            <div className="row">
              {dateFields.map((day, index) => (
                <div className="col-lg-4" key={index}>
                  <div className="mb-3">
                    <label htmlFor={`dateInput${day}`} className="form-label">
                      Date: {day}
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id={`dateInput${day}`}
                      name={`dateInput${day}`}
                      value={formData.dates[index] || ""} // Use the date at the index
                      onChange={(e) => {
                        const updatedDates = [...formData.dates];
                        updatedDates[index] = e.target.value; // Update the date at the specific index
                        setFormData((prevData) => ({
                          ...prevData,
                          dates: updatedDates,
                        }));
                      }}
                      min={1}
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="mb-3">
              <label htmlFor="continueDays" className="form-label">
                Continue Days
              </label>
              <input
                type="number"
                className="form-control"
                id="continueDays"
                name="continueDays"
                value={formData.continueDays}
                onChange={handleInputChange}
                min={1}
                onwheel={(e) => e.preventDefault()}
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="numberOfPigeons"
                className="form-label d-flex justify-content-between"
              >
                Number of Pigeons <span>Max 50</span>
              </label>
              <input
                type="number"
                className="form-control"
                id="numberOfPigeons"
                name="numberOfPigeons"
                value={formData.numberOfPigeons}
                onChange={handleInputChange}
                min={0}
                max={50}
                onwheel={(e) => e.preventDefault()}
              />
            </div>

            <div className="mb-3">
              <label
                htmlFor="helperPigeons"
                className="form-label d-flex justify-content-between"
              >
                Helper Pigeons <span>Max 50</span>
              </label>
              <input
                type="number"
                className="form-control"
                id="helperPigeons"
                name="helperPigeons"
                value={formData.helperPigeons}
                onChange={handleInputChange}
                min={0}
                max={50}
                onwheel={(e) => e.preventDefault()}
              />
            </div>

            <div className="mb-3">
              <label
                htmlFor="numberOfPrizes"
                className="form-label d-flex justify-content-between"
              >
                Number of Prizes <span>Max 50</span>
              </label>
              <input
                type="number"
                className="form-control"
                id="numberOfPrizes"
                name="numberOfPrizes"
                value={formData.numberOfPrizes}
                onChange={handleNumberChange}
                min={0}
                max={50}
                onwheel={(e) => e.preventDefault()}
              />
            </div>

            <div className="row">
              {Array.from({ length: numberOfPrizes }, (_, index) => (
                <div className="col-lg-4" key={index}>
                  <div className="mb-3">
                    <label className="form-label">Prize {index + 1}</label>
                    <input
                      type="text"
                      className="form-control"
                      name={`prize${index + 1}`}
                      value={formData.prizes[index] || ""} // Use the value from formData
                      onChange={(e) => {
                        const updatedPrizes = [...formData.prizes];
                        updatedPrizes[index] = e.target.value; // Update the prize at the specific index
                        setFormData((prevData) => ({
                          ...prevData,
                          prizes: updatedPrizes, // Update the prizes array in formData
                        }));
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>

            {loginUser?.role === "admin" && (
              <div className="mb-3">
                <label htmlFor="status" className="form-label">
                  Show on screen
                </label>
                <select
                  className="form-control"
                  id="status"
                  name="status"
                  value={formData.status}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Select an option
                  </option>
                  <option value="Active">On Screen</option>
                  <option value="Non-Active">Off Screen</option>
                </select>
              </div>
            )}

            <div className="mb-3">
              <label htmlFor="numberOfPigeons" className="form-label">
                Pigeons Owners
              </label>
              <div className="position-relative">
                <input
                  type="text"
                  placeholder="Search pigeon owner by name"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="form-control"
                />
                <Button className="position-absolute top-50 end-0 translate-middle-x translate-middle-y">
                  Search
                </Button>
              </div>
              <div
                className=" border rounded-3 rounded-top-0"
                style={{ maxHeight: "300px", overflowY: "auto" }}
              >
                <div className="d-flex flex-column gap-2 px-4">
                  <table className="table bordered-table mb-0">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">
                          #
                        </th>
                        <th scope="col" className="text-center">
                          Pigeon Owners
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredOwners?.map((owner, index) => (
                        <tr
                          key={index}
                          className={`cursor-pointer ${
                            selectedOwners?.includes(owner._id)
                              ? "selected-row"
                              : ""
                          }`}
                          onClick={() => handleRowClick(owner?._id)}
                        >
                          <td
                            className={`text-center ${
                              selectedOwners?.includes(owner._id)
                                ? "text-white"
                                : ""
                            }`}
                          >
                            {selectedOwners?.includes(owner._id) ? (
                              <span>&#10004;</span>
                            ) : (
                              pigeonOwners?.indexOf(owner) + 1
                            )}
                          </td>
                          <td className="text-center">
                            <div className="d-flex align-items-center justify-content-start gap-2">
                              <div
                                className="overflow-hidden d-flex align-items-center justify-content-center"
                                style={{
                                  height: "60px",
                                  width: "60px",
                                  borderRadius: "100%",
                                }}
                              >
                                <img
                                  src={owner.ownerPicture}
                                  className="h-100 w-100"
                                  alt=""
                                  style={{
                                    objectFit: "cover",
                                    objectPosition: "center",
                                  }}
                                />
                              </div>
                              <div className="d-flex flex-column align-items-start">
                                <span className="fw-bold">{owner?.name}</span>
                                <span>{owner?.address}</span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {loading ? "Creating..." : "Create Tournament"}
            </button>
          </form>
        </div>
      </div>
    </MasterLayout>
  );
};

export default CreateTournaments;
